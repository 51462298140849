import axios from 'axios';
import constants from "../constants";
import {getHeadersWithoutAuth} from "./helpers";


export async function getCartTotals(cart) {
    return axios.post("https://cocunatapi.net/cart-totals/totals?withRewards=true", cart)
}

/**
 * Returns the number of products.
 * @param {object} cart
 */
export function getNumberOfProductsFromOrderlines(cart) {
    let number = cart.orderlines.reduce((total, currentValue) => {
        return total + currentValue.number;
    }, 0);
    return number
}


/**
 * Save cart information to the Cloudflare checkouts KV
 *
 * @param {Object} cart
 * @param {Object} paymentMeta
 */
export async function saveCart(cart, paymentMeta) {
    let userData = {
        id: null,
        email: cart.address.email,
        first_name: cart.address.first_name,
        last_name: cart.address.last_name,
    };
    const orderPayload = {
        ...cart,
        userData: userData,
        cart_meta: {
            ...paymentMeta
        },
    };

    const checkouts = [
        {
            id: cart.cart_id,
            checkout: orderPayload,
        },
    ];

    // We save stripe with both the cart and payment id as keys.
    if ('stripe' in paymentMeta) {
        checkouts.push({
            id: paymentMeta.stripe.id,
            checkout: orderPayload,
        });

        checkouts.forEach((c) => {
            c.checkout.payment_token = paymentMeta.stripe.id;
        });
    }
    await Promise.all(checkouts.map((obj) => createCheckout(obj)));
}


/**
 * Save cart information to the checkout DynamoDb
 *
 * @param {Object} cart
 */
export async function saveCartDynamoDb(cart) {
    return axios.post(constants.AWS_CART_CHECKOUTS, cart, getHeadersWithoutAuth())
}

function createCheckout(checkout_obj) {
    if (
        !checkout_obj.checkout.address ||
        !checkout_obj.checkout.orderlines ||
        !checkout_obj.checkout.orderlines.length
    ) {
        throw new Error("Invalid checkout");
    }

    return axios.post(constants.WORKER_CHECKOUTS, checkout_obj, getHeadersWithoutAuth());
}

export async function getPromo(promo){
    return await axios.get(`https://proxy.cocunat.com/promotions-api/${promo}`)
}