function Price({prices}){
    return (
        <>
            {prices && prices.discounted && prices.price && parseFloat(prices.price) !== parseFloat(prices.discounted) ?
                <span className="price-product">
                    <span className="actual-price">{prices.discounted}€</span><span className="strike-price">{prices.price}€</span>
                </span>
            : prices &&
            <span className="price-product">
                <span className="actual-price">{prices.price}€</span>
            </span>
            }
        </>
    )
}

export default Price;