import {t} from '../helpers'
import { useContext } from "react";
import { CartContext } from '../context/cart';

function SearchBar({value, onChangeInput, onSubmit, onClickScan}){
    function handleKeyDown(e){
        if (e.keyCode === 13) {
            onSubmit()
        }
    }

    const {cart} = useContext(CartContext)
    let lang = cart ? cart.language : "es";
    return (
        <section id="search-bar">
            <div className="container">    
                <div className="input col-sm-12 col-xs-12">
                    <input 
                        type="text"
                        placeholder={t('search-placeholder',lang)}
                        onChange={onChangeInput}
                        value={value}
                        onKeyDown={handleKeyDown}
                    />
                    <button onClick={onSubmit}>⌕</button>
                </div>
            </div>
        </section>
    )
}

export default SearchBar;