export function getHeadersWithoutAuth() {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
}

export function inputValidationError(field,value){
        
    let rules = {
        "email":{
            minLength:1,
            shouldContain: ['@','.']
        },
        "first_name":{
            minLength:2,
        },
        "last_name":{
            minLength:2,
        },
        "city":{
            minLength:2,
        },
        "address":{
            minLength:2,
        },
        "zipCode":{
            minLength:1,
        },
        "phone":{
            minLength:5,
        },
    }
    if (rules.hasOwnProperty(field)){
        if (rules[field].hasOwnProperty("minLength") && value.length < rules[field].minLength ){
            return `El campo ${field} tiene que tener como mínimo ${rules[field].minLength} carácteres`
        }
        
        if (rules[field].hasOwnProperty("shouldContain")){
            for (let i = 0; i < rules[field].shouldContain.length; i++){
                if (!(value.indexOf(rules[field].shouldContain[i]) > -1)){
                    return 'El email no es válido'
                }
            }
        }
    }
    return null
}
