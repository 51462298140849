module.exports = {
    "es": {
        "seo_main_title": "Cocunat - Tienda online de cosmetica natural",
        "seo_global": "Cocunat",
        "seo_cart": "Cocunat - Carrito",
        "seo_checkout": "Cocunat - Checkout",
        "seo_search": "Cocunat - Search",
        "seo_product_single": "Cocunat",
        "seo_thanks": "Cocunat - Thanks",
        "seo_account": "Cocunat - Account",
        "seo_login": "Cocunat - Login",
        "add-to-cart": "Añadir al carrito",
        "comments": "Comentarios",
        "perks-free-shipping": "ENVÍO GRATUITO A PARTIR DE 39€",
        "perks-devolutions": "DEVOLUCIONES SENCILLAS",
        "perks-results": "RESULTADOS GARANTIZADOS",
        "ingredients-title": "El secreto está en nuestros fantásticos ingredientes",
        "tutorial-title": "CÓMO USAR",
        "ideal-for-title":"IDEAL PARA",
        "curly-text": "Descubre cómo utilizarlo según tu tipo de rizo",
        "curly-button": "DESCÚBRELO",
        "rating-based": "Basado en {{num}} comentarios",
        "face": "Cara",
        "body": "Cuerpo",
        "hair": "Cabello",
        "packs": "Packs",
        "sample": "Muestras",
        "contact": "Contacto",
        "terms_of_service": "Términos y condiciones",
        "delivery_returns_policy": "Política de envíos y devoluciones",
        "safe_purchase": "Compra Segura",
        "privacy_policy": "Política de Privacidad",
        "security_and_privacy": "Seguridad y privacidad",
        "our_products": "Nuestros productos",
        "nw_button": "Vamos",
        "nw_title": "Únete al movimiento Cocunat",
        "nw_text": "e inspírate con nuevos contenidos, ofertas especiales y mucho más!",
        "click_buy": "¡Las influencers más TOP del momento adoran Cocunat!",
        "about_us": "Sobre nosotros",
        "work_with_us": "¡Trabaja con nosotros!",
        "category-65": "Cara",
        "category-65-description": "Que tu piel no pase sed! Hidrátala y mucho más...",
        "category-66": "Cuerpo",
        "category-66-description": "Hidrata, revitaliza o relaja tu cuerpo. Tomate tu tiempo",
        "category-67": "Cabello",
        "category-67-description": "Limpia, nutre y revitaliza. Un cabello saludable para presumir",
        "category-90": "Packs",
        "category-90-description": "Juntos, son todavía mejores",
        "category-91": "Los 'must' de tu neceser",
        "category-91-description": "Muestras",
        "home-title": "Marca referente en el mercado de la cosmética natural que mima tu piel y tu cabello: <strong>productos 100% naturales, toxic free, veganos, cruelty free y sostenibles.</strong>",
        "all-products": "Todos los productos",
        "category-home-65-title": "Linea Facial",
        "category-home-65-desc": "Hidrata tu piel y mucho más",
        "category-home-66-title": "Linea Corporal",
        "category-home-66-desc": "Hidrata, revitaliza o relaja tu cuerpo",
        "category-home-67-title": "Linea Capilar",
        "category-home-67-desc": "Limpia, nutre y revitaliza. Un cabello saludable para presumir",
        "category-home-80-title": "C.NoMakeup",
        "category-home-80-desc": "Lista en 5 minutos con un resultado espectacular",
        "banner-middle-home-title": "¡Descubre C.NoMakeUp!",
        "banner-middle-home-desc": "¿No sabes cuál elegir? ¡Haz el quiz y encuentra tu tono ideal!",
        "banner-middle-home-button-text": "Encuentra tu tono",
        "what-make-us-different-title": "¿Qué nos hace únicos?",
        "what-make-us-different-1": "Todos nuestros productos son 100% Toxic Free",
        "what-make-us-different-2": "Somos sostenibles",
        "what-make-us-different-3": "Cruelty Free",
        "what-make-us-different-4": "Asesoría con expertos",
        "top-header-text": "Envíos en {{days}} días laborables, gratis a partir de {{free_shipping}}",
        "home-slide-text-1":"",
        "home-slide-btn-text-1":"Elige la tuya",
        "home-slide-mobile-img-1":"all_in_one_slider_mobile_es_new.jpg?v=1",
        "home-slide-desktop-img-1":"all_in_one_slider_desktop_es_new.jpg?v=1",
        "home-slide-link-1":"https://cocunat.com/product/all-in-one",
        "home-slide-text-2":"¡Nuevos tonos Beauty Base & Magic Brush!",
        "home-slide-btn-text-2":"Encuentra tu tono",
        "home-slide-mobile-img-2":"new-cnmu-tones-mobile.jpg",
        "home-slide-desktop-img-2":"new-cnmu-tones-desktop.jpg?v=1",
        "home-slide-link-2":"https://cocunat.com/makeup-quiz/",
        "home-slide-text-3":"El protector solar que cuida del océano",
        "home-slide-btn-text-3":"COMPRAR",
        "home-slide-mobile-img-3":"_-Mobile_solar_def.jpg",
        "home-slide-desktop-img-3":"1-Desktop-SOLAR.jpg",
        "home-slide-link-3":"https://cocunat.com/product/natural-protector-spf-30",
        "home-slide-text-4":"",
        "home-slide-btn-text-4":"COMPRAR",
        "home-slide-mobile-img-4":"Slider-BSL-21-20-Mobile-ES-def-.jpg",
        "home-slide-desktop-img-4":"Slider-BSL-21-20-Desktop-ES.jpg",
        "home-slide-link-4":"https://cocunat.com/search/?category=09032021",
        "view-all-reviews":"Ver todos los comentarios",
        "write_review":"Añadir un comentario",
        "new_comment": "Nuevo comentario",
        "add_review":"Añadir",
        "star":"Estrellas",
        "subscription-terms-button": "Aquí más información.",
        "subscription-one-time": "Compra única",
        "subscription-with-discount": "{{quantity}}% de descuento con la subscripción",
        "subscription-terms-summary": "Puedes cancelar o gestionar tu suscripción en cualquier momento desde tu cuenta de usuario. Solo se realizará el pago cuando tu pedido se haya enviado. Siempre te llegará un email 15 días antes del envío de tu suscripción para gestionarla o para cancelarla.",
        "subscription-terms": "Para disfrutar cada mes de un nuevo UNBEATABLE deberás suscribirte escogiendo en esta ficha la modalidad de suscripción que más te convenga: mensual, 2 meses, 3 meses, 4 meses, 5 meses, 6 meses.<br/><br/>Tras hacer click sobre el botón de 'AÑADIR AL CARRITO' simplemente deberás cumplimentar los datos solicitados para poder tramitar tu pedido: dirección de envío e información de pago, y cuando se realice el pago con el descuento de la suscripción habrás realizado correctamente la suscrición para tu próximo UNBEATABLE.<br/><br/>La suscripción al UNBEATABLE se prorrogará automáticamente por períodos idénticos salvo que canceles tu suscripción o cambies la periodicidad. IMPORTANTE: puedes cancelar tu suscripción entrando en tu cuenta de usuario y utilizando el enlace de cancelar suscripción. En el caso de que hubieras escogido la suscripción mensual, la cancelación de la misma surtirá efecto el mes siguiente de aquél en el que se haya ejercitado la cancelación.",
        "subscription-option": "Entrega cada {{num}} semanas",
        "subscription-option-default": "Entrega cada {{num}} semanas (recomendado)",
        "view_product":"Ver producto",
        "reviews" :"Comentarios",
        "home_revolution":
          "¡Ha llegado <br>la <span style='text-decoration:underline;'>REVOLUCIÓN!</span>",
        "home_revolution_pink":
          "Sostenibilidad, innovación, naturalidad, libertad y amantes de los animales ",
        "home_revolution_text":
          "son algunos de los valores que definen Cocunat. Somos la marca que creó el concepto TOXIC FREE, y queremos conquistarte a ti, al planeta, a los animales y a la vida con la mejor cosmética libre de tóxicos.",
        "home_welcome_cocunat": "¡BIENVENIDOS A COCUNAT!",
        "no_nasties": "no nasties",
        "no_lies": "no lies",
        "no_toxics": "SOMOS LA ÚNICA MARCA REALMENTE SALUDABLE Y 100% SIN TÓXICOS",
        "home-welcome-link":"https://cocunat.com/es-es/content/bienvenidos",
        "best_sellers": "Los más vendidos",
        "wow_section_title": "Nuestros productos son ...",
        "wow_section_subtitle":
          "Formulamos nuestros productos con I+D de alta complejidad para crear productos innovadores,con resultados profesionalesy efecto WOW.",
        "wow_section_li_1": "Inspirados en la biotecnología",
        "wow_section_li_2": "Con activos innovadores",
        "wow_section_li_3": "Revolucionarios",
        "wow_section_li_4": "Con ingredientes de eficacia demostrada",
        "wow_section_li_5": "Premiados internacionalmente",
        "wow_section_li_6": "Materias primas no contaminantes",
        "wow_section_li_7": "Y siempre siempre libres de crueldad animal",
        "press_title": "Los productos de los que todos hablan",
        "home_ig_title": "Descubre lo que dicen de nosotros",
        "ig_title": "cocunat eres tú",
        "ig_follow":
          'Sigue a <span>@cocunat</span> en Instagram y etiqueta tus productos favoritos con <span>#cocunatgirls</span>',
        "shop_by_range": "Shop by range",
        "home_ig_text_1":"Tienes dudas? Nuestro equipo de expertas estar\u00e1n encantadas de ayudarte a tener una piel y un pelazo #sinfiltros.  ;)",
        "home_ig_text_2":"Somos la \u00fanica marca del mercado Toxic Free (made in Spain) que retira m\u00e1s de 9000 sustancias susceptibles de ser t\u00f3xicas. S\u00famate al efecto WOW con nuestros productos veganos y cruelty free. ",
        "home_ig_text_3":" Hidratante de tratamiento para la zona delicada del contorno de ojos a base de Cafe\u00edna, \u00c1cido Hialur\u00f3nico, Karit\u00e9 y Germen de Trigo. Bye bye ojeras y bolsas con  tu Contorno de Ojos favorito. \u00a1Y envase de vidrio! ",
        "home_ig_text_4":"\u00bfSab\u00edas que la Wondermask sirve para combatir Blue Light?  Nuestra WONDERMASK protege tu piel de los radicales libres con las vitaminas A, C y E. \u2060 S",
        "home_ig_text_5":"En Cocunat amamos los animales por eso somos 100% cruelty free y dejamos que nuestros compa\u00f1eros de vida vengan a la oficina.",
        "home_ig_text_6":"Gracias a su tecnolog\u00eda en I+D, podr\u00e1s tener tu cabello perfecto, sin apelmazar, hidratado y consiguiendo evitar un corte de puntas no deseado. Ideal para cabello seco, da\u00f1ado y curly.",
        "home_ig_text_7":" El boom antiaging que todas est\u00e1is buscando: The Absolute! La crema premium antiedad perfecta para tener una piel hidratada, tersa e iluminada. ",
        "home_press_text_1": " \u201cLa crema que se agot\u00f3 en tan solo dos semanas.\u201d",
        "home_press_text_2":
                "\u201cEsta marca nace en Barcelona con un compromiso firme: crear la mejor cosm\u00e9tica totalmente libre de t\u00f3xicos y crueldad animal.\u201d",
        "home_press_text_3":
                "\u201cIdeal para hidratar y calmar la piel (tambi\u00e9n despu\u00e9s de la ducha) sin rastro de pegajosidad\u2026\u201d ",
        "home_press_text_4":
                "\u201cEs 100% toxic free, 100% cruelty free, nutre, hidrata, regenera, reafirma y alisa la piel, reduce, trata y previene las estr\u00edas y, por si fuera poco, deja un brillo precioso y un olor a vainilla s\u00faper relajarte en la piel\u201d",
        "home_press_text_5":
                " \u201cUna sinergia perfecta de antioxidantes, vitaminas, \u00e1cidos grasos y fitoesteroles para una tez m\u00e1s joven, lisa y sedosa.\u201d",
        "home_press_text_6":
                "\u201cTodos los productos de la marca son 100% libres de t\u00f3xicos y formulados al m\u00e1ximo con ingredientes naturales.\u201d",
        "search-placeholder":"Escribe aquí tu búsqueda",
        "pay":"Pagar",
        "your_cart": "Tu compra",
        "keep_shopping": "Seguir comprando",
        "delete_item": "SUPRIMIR",
        "total": "Total",
        "subtotal": "Subtotal",
        "balance": "Saldo a favor",
        "discount": "Descuentos",
        "subtotal_with_discount": "Subtotal con descuento",
        "shipping_cost": "Gastos de envío",
        "promo_code_question": "¿Tienes un código de descuento?",
        "promo_code": "Código de descuento",
        "apply": "Aplicar",
        "promo_cart_banner":"¡Consigue un <strong>HEADBAND + 3 MUESTRAS GRATIS</strong> en tus compras superiores a 100%!",
        "address_title": "Dirección de envío",
        "name": "Nombre",
        "last_name": "Apellidos",
        "address": "Dirección",
        "zip_code": "Código Postal",
        "phone": "Teléfono",
        "country": "País",
        "your_email": "Tu correo electrónico",
        "city": "Ciudad",
        "go_to_cart":"Ir al carrito",
        "delivery_shop":"En tienda",
        "delivery_home":"Enviar a casa",
        "cash":"Efectivo",
        "card":"Tarjeta",
        "payment_method":"Método de pago",
        "edit_address": "Editar dirección",
        "change":"Cambiar",
        "order_error":"Error de pedido",
        "ask_expert_error":"Por favor dirígete a caja para finalizar el pedido",
        "thanks":"¡Gracias {{name}} tu pedido ha sido realizado con éxito!",
        "go_caja":"Dirígete a caja para finalizar tu compra"
        },
    "en": {
        "seo_main_title": "Cocunat - Natural and toxic-free cosmetics",
        "seo_global": "Cocunat",
        "seo_cart": "Cocunat - Cart",
        "seo_checkout": "Cocunat - Checkout",
        "seo_search": "Cocunat - Search",
        "seo_product_single": "Cocunat",
        "seo_thanks": "Cocunat - Thanks",
        "seo_account": "Cocunat - Account",
        "seo_login": "Cocunat - Login",
        "add-to-cart": "Add to cart",
        "comments": "Comments",
        "perks-free-shipping": "Free standard delivery on all orders over {{total}}",
        "perks-devolutions": "Easy returns",
        "perks-results": "Guaranteed results",
        "ingredients-title": "The secret lies in our amazing ingredients",
        "tutorial-title": "How to use",
        "ideal-for-title":"IDEAL FOR",
        "curly-text": "Discover how to use it according to your curl type",
        "curly-button": "Find out",
        "rating-based": "Based on {{num}} comments",
        "face": "Face",
        "body": "Body",
        "hair": "Hair",
        "packs": "Bundles",
        "sample": "Samples",
        "contact": "Contact",
        "terms_of_service": "Terms of Service",
        "delivery_returns_policy": "Delivery and returns policy",
        "safe_purchase": "Secure payment",
        "privacy_policy": "Privacy Policy",
        "security_and_privacy": "Security & privacy",
        "our_products": "Our products",
        "nw_button": "Let's go",
        "nw_title": "Join the Cocunat movement ",
        "nw_text": "and be inspired by new content, special offers and so much more!",
        "click_buy": "The most TOP influencers of the moment love Cocunat!",
        "about_us": "About us ",
        "work_with_us": "Join Us!",
        "category-65": "Face",
        "category-65-description": "So your skin doesn't go thirsty! Products that hydrate and so much more...",
        "category-66": "Body",
        "category-66-description": "Hydrate, revitalise and tone your body. Take your time!",
        "category-67": "Hair",
        "category-67-description": "Clean, nourish and revitalise. Healthy hair to brag about",
        "category-90": "Bundles",
        "category-90-description": "Together, they're even better",
        "category-91": "Must-haves for your bag",
        "category-91-description": "Samples",
        "home-title": "The leading brand in natural cosmetics that takes care of your skin and hair. 100% natural, toxic free, vegan, cruelty free, and sustainable products.",
        "all-products": "All products",
        "category-home-65-title": "Facial Line",
        "category-home-65-desc": "Moisturize your skin and much more",
        "category-home-66-title": "Body Line",
        "category-home-66-desc": "Moisturize, revitalize or relax your body",
        "category-home-67-title": "Hairline",
        "category-home-67-desc": "Clean, nourish and revitalise. Healthy hair to brag about",
        "category-home-80-title": "C.NoMakeup",
        "category-home-80-desc": "Ready in 5 minutes with a spectacular result",
        "banner-middle-home-title": "Discover C.NoMakeUp!",
        "banner-middle-home-desc": "Not sure which shade to choose?<br> take the quiz and find your ideal shade!",
        "banner-middle-home-button-text": "Find your shade",
        "what-make-us-different-title": "What makes us unique?",
        "what-make-us-different-1": "All of our products are 100% Toxic Free",
        "what-make-us-different-2": "We are sustainable",
        "what-make-us-different-3": "Cruelty Free",
        "what-make-us-different-4": "Advice from experts",
        "top-header-text": "Free {{days}} days delivery on all orders over {{free_shipping}}",
        "home-slide-text-1":"",
        "home-slide-btn-text-1":"Choose yours!",
        "home-slide-mobile-img-1":"all_in_one_slider_mobile_en_new.jpg?v=1",
        "home-slide-desktop-img-1":"all_in_one_slider_desktop_en_new.jpg?v=1",
        "home-slide-link-1":"https://cocunat.com/product/all-in-one",
        "home-slide-text-2":"New Beauty Base & Magic Brush Shades!",
        "home-slide-btn-text-2":"Find your shade",
        "home-slide-mobile-img-2":"new-cnmu-tones-mobile.jpg",
        "home-slide-desktop-img-2":"new-cnmu-tones-desktop.jpg?v=1",
        "home-slide-link-2":"https://cocunat.com/makeup-quiz/",
        "home-slide-text-3":"The sunscreen that cares for the ocean",
        "home-slide-btn-text-3":"BUY",
        "home-slide-mobile-img-3":"_-Mobile_solar_def.jpg",
        "home-slide-desktop-img-3":"1-Desktop-SOLAR.jpg",
        "home-slide-link-3":"https://cocunat.com/product/natural-protector-spf-30",
        "home-slide-text-4":"",
        "home-slide-btn-text-4":"BUY",
        "home-slide-mobile-img-4":"Slider-BSL-21-20-Mobile-INT-def-.jpg",
        "home-slide-desktop-img-4":"Slider-BSL-21-20-Desktop-INT.jpg",
        "home-slide-link-4":"https://cocunat.com/search/?category=09032021",
        "view-all-reviews":"View all comments",
        "write_review":"Add a review",
        "new_comment": "New review",
        "add_review":"Add",
        "star":"Stars",
        "subscription-terms-button": "Click here for more information.",
        "subscription-one-time": "One time purchase",
        "subscription-with-discount": "{{quantity}}% off with subscription",
        "subscription-terms-summary": "You can cancel or change your subscription at anytime through your account. The payment will only go through once your product has been shipped. You will always receive an email 15 days before shipment day incase you'd like to cancel or change your subscription.",
        "subscription-terms": "<p>To enjoy a new UNBEATABLE every month, you should subscribe based on whichever subscription mode is best for you: monthly, every 2 months, every 3 months, every 4 months, every 5 months, or every 6 months.</p><p>After clicking ADD TO CART you should simply provide the required information in order to complete your order: your mailing address and payment information. When you complete the payment with the subscription discount, you will have correctly requested your subscription for the UNBEATABLE.</p><p> The UNBEATABLE subscription will be automatically extended for identical periods unless the client cancels their subscription or changes the duration. The customer can cancel their subscription by logging into their account at www.cocunat.com and using the unsubscribe link. In the event that the client has chosen the monthly subscription, the cancellation will take effect the month following the one in which the cancellation was requested.</p>",
        "subscription-option": "{{quantity}}% off with subscription",
        "subscription-option-default": "Delivery every {value} weeks (recommended)",
        "view_product":"View product",
        "reviews": "Reviews",
        "home_revolution": "The REVOLUTION is here!",
        "home_revolution_pink": "Sustainability, innovation, naturalness, freedom and animal lovers ",
        "home_revolution_text":
          "are just some of the values that define Cocunat. We are the brand that created the TOXIC FREE concept and we want to win you over, while doing our bit for the planet, animals and life in general with the best toxic free cosmetics.",
        "home_welcome_cocunat": "Welcome Cocunateers!",
        "no_nasties": "no nasties",
        "no_lies": "no lies",
        "no_toxics": "We are the only brand that is truly sustainable and 100% toxic free",
        "home-welcome-link":"https://cocunat.com/en-gb/content/welcome",
        "best_sellers": "Best Selling Products",
        "wow_section_title": "Our products are...",
        "wow_section_subtitle":
          "Based on highly complex R&D, we formulate innovative products with professional results and the WOW effect.",
        "wow_section_li_1": "Inspired by biotechnology",
        "wow_section_li_2": "Formulated with innovative active ingredients",
        "wow_section_li_3": "Revolutionary",
        "wow_section_li_4": "Made using ingredients with proven effectiveness",
        "wow_section_li_5": "Internationally acclaimed",
        "wow_section_li_6": "Formulated with non-polluting raw materials",
        "wow_section_li_7": "ALWAYS cruelty free",
        "press_title": "The products everyone is talking about ",
        "home_ig_title": "Find out what they're saying about us",
        "ig_title": "you are cocunat",
        "ig_follow":
          'Get social with <span style="color:#ff8172;"> @cocunat_int</span> and tag <span style="color:#ff8172;">#cocunatgirls</span> so we can show you off on our feed',
        "home_ig_text_1":"Do you have any questions? Our team of experts would love to help you have the perfect skin and head of hair #withoutfilters. ;)",
        "home_ig_text_2":"We are the only brand in the Toxic Free market (made in Spain) that removes more than 9,000 substances that are likely to be toxic.",
        "home_ig_text_3":"Hydrating regimen, based on Caffeine, Hyaluronic Acid, Shea and Wheat Germ, for the delicate area beneath the eyes. Say goodbye to dark circles and bags with your favourite Eye Contour Cream. And it\u2019s in a glass bottle!",
        "home_ig_text_4":"Did you know that the Wondermask works to combat Blue Light? \ud83c\udf19 With vitamins A, C and E, our WONDERMASK protects your skin against free radicals.",
        "home_ig_text_5":"At Cocunat we love animals. For this reason, we are 100% Cruelty Free and we let our life partners come to the office with us.",
        "home_ig_text_6":"Thanks to its R&D technology, you can have perfect hair by having time to clean it and avoiding cutting the ends. It\u2019s perfect for dry, damaged and curly hair. ",
        "home_ig_text_7":"The anti-ageing boom that you have all been looking for: The Absolute. A perfect synergy to have hydrated, smooth, bright, and young skin.",
        "home_press_text_1": " \u201cThe cream that sold out in only 2 weeks\u201d",
        "home_press_text_2":
                  "\u201cThis brand was created in Barcelona with a solid commitment: to create the best cosmetics that are completely free of toxins and animal cruelty.\u201d",
        "home_press_text_3":
                  "\u201cPerfect for hydrating and calming the skin (also after the shower) without a sticky feeling\u2026\u201d ",
        "home_press_text_4":
                  "\u201cIt\u2019s 100% toxin-free and 100% cruelty free. It nourishes, hydrates, regenerates, reaffirms and smoothes out the skin. It reduces, treats and prevents stretch marks and, as if it wasn\u2019t already good enough, it leaves a beautiful shine and a super relaxing vanilla scent on the skin.\u201d",
        "home_press_text_5":
                  " \u201cA perfect synergy of antioxidants, vitamins, fatty acids and phytosterols for a younger, smoother and softer complexion.\u201d",
        "home_press_text_6":
                  "\u201cAll the products from the brand are 100% toxin-free and formulated to the fullest with natural ingredients\u201d",
        "search-placeholder":"Enter your search here",
        "pay":"Pay",
        "your_cart": "Your shopping bag",
        "keep_shopping": "Continue shopping",
        "delete_item": "Delete",
        "total": "Total",
        "subtotal": "Subtotal",
        "balance": "Balance",
        "discount": "Discount",
        "subtotal_with_discount": "Subtotal with discount",
        "shipping_cost": "Shipping cost",
        "promo_code_question": "Do you have a discount code?",
        "promo_code": "Discount Code",
        "apply": "Apply",
        "promo_cart_banner":"Get a <strong>HEADBAND + 3 FREE SAMPLES </strong>on your purchases over 100€!",
        "address_title": "Delivery address",
        "name": "Name",
        "last_name": "Last Name",
        "address": "Address",
        "zip_code": "Zip Code",
        "phone": "Phone",
        "country": "Country",
        "your_email": "E-mail",
        "city": "City",
        "go_to_cart":" Go to cart",
        "delivery_shop":"In shop",
        "delivery_home":"Home delivery",
        "cash":"Cash",
        "card":"Credit Card",
        "payment_method":"Payment method",
        "edit_address": "Edit address",
        "change":"Change",
        "order_error":"Order error",
        "ask_expert_error":"Please head to checkout to finalize your order.",
        "thanks":"Thank you {{name}} your order has been placed successfully!",
        "go_caja":"Please head to checkout to finalize your purchase."
    },
};