function ProductSkeleton({product}){
    return (
        <div className="col-md-3 col-sm-4 col-xs-6" style={{marginBottom: 50}}>
            <div className="skeleton-box" style={{width: "100%", height: "150px"}}></div>

            <span className="skeleton-line" style={{width: "90%", marginTop: 10}}></span>
            <span className="skeleton-line" style={{width: "100%"}}></span>

            <div className="skeleton-box" style={{width: "100%", height: "35px", marginTop: 20}}></div>
        </div>
    )
}

export default ProductSkeleton;