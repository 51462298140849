import {useState, useEffect} from 'react'

function Dropdown({
    onSelect,
    defaultSelected,
    options,
    placeholder="Select a value",
    style
}){
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        console.log(defaultSelected)
        if(defaultSelected !== ""){
            setSelected(defaultSelected)
        }
    }, [])

    function _onSelect(option){
        setSelected(option);
        onSelect(option.key);
    }

    return (
        <>
            <div className="custom-select" style={style}>
                <div className="custom-select-value">
                    {selected && selected.icon &&
                        <img src={selected.icon} width="25" height="25" alt="icon" />
                    }
                    {selected ?
                        <span>{selected.value}</span>
                    :
                        <span>{placeholder}</span>
                    }
                    <i>▼</i>
                </div>
                <ul>
                    {options.map((option, i) => {
                        return (
                            <li onClick={() => _onSelect(option)} key={i}>
                                {option.icon &&
                                    <img src={option.icon} width="25" height="25" alt="icon" />
                                }
                                <span>{option.value}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default Dropdown;