import RatingStars from "./RatingStars";

function Comment({title, comment, author, rating}){
    return (
        <div className="comment" style={{padding: "15px 0px"}}>
            <p style={{
                fontSize: "19px",
                textTransform: "uppercase",
                margin: "3px 0px 5px 0px",
                lineHeight: "24px"
            }}>{title}</p>
            <strong style={{display: "block"}}>{author}</strong>
            <RatingStars rating={rating} />
            <p>{comment}</p>
        </div>
    )
}

export default Comment;