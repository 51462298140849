function InputGroup({id, title, name, value, onChange, type, style=null}){
    return (
        <div className="input-group" style={style}>
            {/*<label for="name">{title}: </label>*/}
            <input 
                type={type}
                name={name}
                id={id}
                placeholder={title}
                value={value}
                onChange={onChange}
            />
        </div> 
    )
}

export default InputGroup;