import React, {useEffect, useContext} from 'react';
import {overrideBodyClass,buildPhotoUrl, t} from '../helpers'
import { CartContext } from '../context/cart';

function CheckoutError() {
    
    useEffect(() => {
        document.body.classList.add("Hello")
        overrideBodyClass(document.body.classList, "intro");
    }, []);

    const {cart} = useContext(CartContext)
    let lang = cart ? cart.language : "en";



    return (
        <>
            <div className="container thanks">
            <img 
                    src={buildPhotoUrl("photos/store-checkout-no-ok.png")}
                    alt=""
                />
                <p style={{fontSize:"30px", fontFamily:"Brown-Bold", color:"white"}}>{t('order_error',lang)}</p>
                <p style={{fontSize:"25px", fontFamily:"Brown-Regular", color:"white"}}>{t('ask_expert_error',lang)}</p>
                <div style={{textAlign:"center", marginTop:"150px"}}>
                    <p style={{color:"black"}}>© 2020 Cocunat. All Rights Reserved</p>
                </div>   
            </div>
        </>
    )
}

export default CheckoutError;