import React, {useState, useEffect, useContext} from 'react';
import {overrideBodyClass} from '../helpers'
import Dropdown from '../components/Dropdown';
import Button from '../components/Button';
import { CartContext } from '../context/cart';
import { Redirect } from "react-router-dom";


function Home() {
    const {cart, updateCartAttributes} = useContext(CartContext)
    const [redirectTo, setRedirectTo] = useState(false);


    const availableLangs = [
        {"key": "es", "value": "Castellano", "icon": "https://global.cocunat.com/flags/es.png"},
        {"key": "en", "value": "English", "icon": "https://global.cocunat.com/flags/uk.png"}
    ]
    let defaultSelectedLang;

    if(cart && cart.language){
        defaultSelectedLang = availableLangs.filter((item) => item.key === cart.language)[0]
    }else{
        defaultSelectedLang = ""
    }
    
    useEffect(() => {
        document.body.classList.add("Hello")
        overrideBodyClass(document.body.classList, "intro");
    }, []);

    function selectLanguage(_lang){
        updateCartAttributes({"language": _lang})
    }
    if(redirectTo){
        return <Redirect to={`/${cart.language}/search/`} />
    }
    return (
        <>
            <div className="container">
                <h1>¡Bienvenid@ a la experiencia Cocunat <i>Glam</i>!</h1>
                <img 
                    src="https://global.cocunat.com/sello.png" 
                    alt="Cocunat"
                    height="230"
                    width="230"
                />
                <p>Selecciona el idioma para empezar</p>

                <Dropdown
                    key={defaultSelectedLang}
                    style={{width: "220px", margin: "auto", textAlign: "left"}}
                    onSelect={selectLanguage}
                    defaultSelected={defaultSelectedLang}
                    options={availableLangs}
                />

                <Button
                    onClick={() => setRedirectTo(true)}
                    style={{marginTop: 50}}
                    type="primary"
                    size="big"
                >
                    Siguiente
                </Button>
            </div>
        </>
    )
}

export default Home;