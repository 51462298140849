import React, {useContext, useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {CartContext} from '../context/cart';
import CartLine from '../components/CartLine';
import Header from '../components/Header';
import Button from '../components/Button';
import CartTotals from '../components/CartTotals';
import GoBackLink from '../components/GoBackLink';
import PromoCodeInput from '../components/PromoCodeInput';
import PromoCartBanner from '../components/PromoCartBanner';
import {t, overrideBodyClass} from '../helpers'

function Cart() {
    const {
        cart, totals,
        plusOneProduct,
        minusOneProduct,
        deleteProduct,
        applyCartPromo,
    } = useContext(CartContext)

    let [promoCode, setPromoCode] = useState(cart ? cart.promo_code : "")
    let lang = cart ? cart.language : "en";

    useEffect(() => {
        overrideBodyClass(document.body.classList, "cart");
    }, [])

    function applyPromo(promoCode) {
        applyCartPromo(promoCode)
    }

    function onChangePromoCode(e) {
        setPromoCode(e.target.value);
    }

    return (
        <>
            <Header payLink={`/${lang}/checkout/address/`} />

            <div className="container-small">
                <GoBackLink to={`/${lang}/search/`} title={t('keep_shopping', lang)}/>
            </div>

            {cart &&
            <>
                <div className="cart-title-header">
                    <p>{t('your_cart', lang)}</p>
                </div>
                <div className="orderlines-list container-small">
                    {cart.orderlines.map((orderline, i) => {
                        return (
                            <CartLine
                                key={i}
                                orderline={orderline}
                                plusOneProduct={plusOneProduct}
                                minusOneProduct={minusOneProduct}
                                deleteProduct={deleteProduct}
                            />
                        )
                    })}
                </div>
                <div className="presents-list container-small">
                    {cart.presents.map((present, i) => {
                        return (
                            <CartLine
                                key={i}
                                orderline={present}
                                isPresent={true}
                            />
                        )
                    })}
                </div>

                <div className="container-small">
                    {totals && totals.rewards && totals.rewards.next_reward_text &&
                        <PromoCartBanner text={totals.rewards.next_reward_text}/>
                    }

                    {totals && totals.rewards && totals.rewards.current_reward_text &&
                        <PromoCartBanner text={totals.rewards.current_reward_text}/>
                    }

                    <CartTotals />

                    <PromoCodeInput
                        onSubmit={() => applyPromo(promoCode)}
                        value={promoCode}
                        onChangeInput={onChangePromoCode}
                        lang={lang}
                    />

                    <Link to={`/${lang}/checkout/address/`}>
                        <Button size="big" width="wide">CHECKOUT</Button>
                    </Link>
                </div>
            </>
            }
        </>
    )
}

export default Cart;