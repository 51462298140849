function ProductVideo({product}){
    return (
        <section id="video">
            {product.translation.videos.length > 0 &&
                <div
                    className="embed-container"
                    style={{position: "relative", paddingTop:"100%"}}
                >
                <iframe
                    title={product.translation.name}
                    src={`https://player.vimeo.com/video/${product.translation.videos[0].video}?autoplay=1&loop=1&autopause=0&muted=1`}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    loop
                    muted
                    style={{width:"100%", height:"100%", position:"absolute",top:"0",left:"0"}}
                ></iframe>
                </div>
            }
        </section>
    )
}

export default ProductVideo;