import {useState} from 'react';


function RadioGroup({options, type="card", onChange, defaultValue=null}){
    let [selected, setSelected] = useState(false)
    function _onChange(option){
        onChange(option);
        setSelected(option.value)
    }
    return (
        <>
            {options.map((option, i) => (
                <div key={i} className="radio-button" onClick={() => _onChange(option)}>
                    {selected === option.value || defaultValue.value === option.value ?
                        <div className="circle-o"></div>
                    :
                        <div className="circle"></div>
                    }
                    <span>{option.title}</span>
                </div>
            ))}
        </>
    )
}

export default RadioGroup;