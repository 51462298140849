import React, {useState, useEffect, useContext} from 'react';
import {useParams} from "react-router-dom";
import {overrideBodyClass} from '../helpers'
import ProductLoop from '../components/ProductLoop';
import ProductSkeleton from '../components/ProductSkeleton';
import CategoryBar from '../components/CategoryBar';
import {getProducts, getProductsByCategory, getProductsByCategories, getProductSkeletons, searchProducts} from '../logic/products'
import SearchBar from '../components/SearchBar';
import Header from '../components/Header';
import { CartContext } from '../context/cart';


function Search() {
    let { categoryId } = useParams();

    const {cart} = useContext(CartContext)
    let [products, setProducts] = useState([])
    let [searchRealTime, setSearchRealTime] = useState("")
    let [search, setSearch] = useState("")
    let lang = cart ? cart.language : "en";
    let promo = cart ? cart.promo_code : "";

    useEffect(() => {
        const fetchData = async () => {
            setProducts(getProductSkeletons(8))
            let products = []
            if(!categoryId){
                products = await getProducts(lang,promo);
            }else{
                if(categoryId === "90"){
                    products = await getProductsByCategories(categoryId, lang,promo);
                }else{
                    products = await getProductsByCategory(categoryId, lang,promo);
                }
            }
            setProducts(products.data)
        } 
        fetchData()
        overrideBodyClass(document.body.classList, "search");
    }, [categoryId]);

    async function fetchDataSearch(term){
        if (term){
            setSearch(term);
            setProducts(getProductSkeletons(8));
            let products = await searchProducts(term, "es");
            if(products.data.error){
                console.log("ERROR")
            }
            console.log(products)
            setProducts(products.data);    
        } 
    }

    function onChangeSearch(e){
        console.log(e.target.value)
        setSearchRealTime(e.target.value);
    }

    return (
        <>
            <Header goBack={"/"} />
            <section id="hero"></section>
            <CategoryBar activeCategory={categoryId} />
            <SearchBar 
                onClickScan={() => console.log()}
                onSubmit={() => fetchDataSearch(searchRealTime)}
                value={searchRealTime}
                onChangeInput={onChangeSearch}
                lang={lang}
            />
            <div className="product-list container">
                {search !== "" &&
                    <div style={{textAlign: "center", marginBottom: 50}}>
                        <h1 style={{marginBottom: 10}}>Resultados de tu búsqueda</h1>
                        <span>{search}</span>
                    </div>
                }
                {products.map((product, i) => {
                    if(!product.translation || !product.prices || !product.active){
                        return (
                            <div key={i}></div>
                        )
                    }
                    if(product.id === "skeleton"){
                        return (<ProductSkeleton key={i} />)
                    }else{
                        return (<ProductLoop product={product} key={i} />)
                    }
                })}
            </div>
        </>
    )
}

export default Search;