import { useContext } from 'react';
import {buildPhotoUrl} from '../helpers';
import RatingStars from './RatingStars';
import Button from './Button';
import Price from './Price'
import { CartContext } from '../context/cart';
import {Link} from 'react-router-dom'
import {t} from '../helpers'
import PromoSticker from "./PromoSticker";

function ProductLoop({product}){
    const {cart, addProduct} = useContext(CartContext)
    let lang = cart ? cart.language : "es";

    return (
        <div className="product-item col-md-3 col-sm-4 col-xs-6">
            <Link to={`/${lang}/products/${product.translation.slug}`}>
                <img 
                    src={buildPhotoUrl("photos/" + product.image)} 
                    alt={product.translation.name} 
                    height="150"
                    width="150"
                    loading="lazy"
                />
                <div className="product-title">
                    <p style={{fontSize: "14px"}}>
                        <strong style={{fontSize: "16px", display: "block"}}>
                            {product.translation.name}
                        </strong>
                        {product.translation.subtitle}
                    </p>
                </div>
                <PromoSticker discount_applied={product.prices.discount_applied} rendered_from={"product-loop"}/>
                <RatingStars rating={product.translation.rating} />
                <p style={{fontSize: "18px", margin:"10px 0px"}}>
                    <Price prices={product.prices} className="product-loop" />
                    </p>
            </Link>
            <Button onClick={() => addProduct(product)}>{t('add-to-cart', lang)}</Button>
        </div>
    )
}

export default ProductLoop;