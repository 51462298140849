import { useContext, useState } from 'react';
import { CartContext } from '../context/cart';
import {t} from '../helpers'

function CartTotals(props){
    const {cart,totals} = useContext(CartContext)
    const [collapsed, setCollapsed] = useState(false)
    let lang = cart ? cart.language : "es";

    return (
        <div className="totals"> 
            {totals &&
                <>
                    <div className="totals-header" onClick={() => setCollapsed(!collapsed)}>
                        <p><strong>{t('total',lang).toUpperCase()}</strong></p>
                        <div>
                            <strong>{totals.total}€</strong>
                            {collapsed ?
                                <img 
                                    src={"https://global.cocunat.com/angle-up-v2.png"}
                                    width="20"
                                    height="20"
                                    alt="Expand"
                                    style={{marginTop: "7px", marginRight: "-5px", marginLeft: "5px"}}
                                />
                            :
                                <img 
                                    src={"https://global.cocunat.com/angle-down-v2.png"}
                                    width="20"
                                    height="20"
                                    alt="Expand"
                                />
                            }
                        </div>
                    </div>
                    {collapsed &&
                        <div className="totals-content">
                            <p>
                                <strong>{t('subtotal',lang).toUpperCase()}</strong>
                                <span>{totals.subtotal}€</span>
                            </p>
                            {totals.discount > 0 &&
                                <p>
                                    <strong>{t('discount',lang).toUpperCase()}</strong>
                                    <span>-{totals.discount}€</span>
                                </p>
                            }
                            <p>
                                <strong>{t('balance',lang).toUpperCase()}</strong>
                                <span>{totals.balance}€</span>
                            </p>
                            <p>
                                <strong>{t('shipping_cost',lang).toUpperCase()}</strong>
                                <span>{totals.shipping}€</span>
                            </p>
                            <p>
                                <strong>{t('total',lang).toUpperCase()}</strong>
                                <span>{totals.total}€</span>
                            </p>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default CartTotals;