import React, {useState} from 'react';
import Header from '../components/Header';
import Button from '../components/Button'
// import BarcodeScannerComponent from "react-qr-barcode-scanner";

function ScanProduct() {

    const [data, setData] = useState("Not Found");

    return (
        <>
            <Header />
            <div className="container-small" style={{textAlign:"center", margin:"auto"}}>
                <h3>ESCANER</h3>
                <h1>{data}</h1>     
                {/*<BarcodeScannerComponent
                    onUpdate={(err, result) => {
                    if (result) setData(result.text);
                    else setData("Not Found");
                    }}
                />*/}
                           
                <Button size="big" style={{margin:"30px auto"}}>ESCANEAR EL CÓDIGO DE BARRAS</Button>
                <p style={{textDecoration:"underline", fontSize:"16px", maxWidth:"300px", margin:"auto"}}>
                    Si tienes problemas no dudes en pedir ayuda a nuestras asesoras en la tienda!
                </p>
            </div>
           
            
        </>
    )
}

export default ScanProduct;