import React, {useState, useEffect, useContext} from 'react';
import {useParams} from "react-router-dom";
import { CartContext } from '../context/cart';
import Header from '../components/Header';
import Comment from '../components/Comment';
import {getProductBySlug, getProductComments} from '../logic/products'
import Carousel from '../components/Carousel';
import Price from '../components/Price';
import RatingStars from '../components/RatingStars';
import {t} from '../helpers'
import Button from '../components/Button'
import Packaging from '../components/Packaging';
import ProductVideo from '../components/ProductVideo';
import RadioGroup from '../components/RadioGroup';
import CONSTANTS from '../constants';
import PromoSticker from "../components/PromoSticker";


function ProductSingle() {
    let { slug } = useParams();

    const {cart, addProduct} = useContext(CartContext)
    let [product, setProduct] = useState(null)
    let [comments, setReviews] = useState(null)
    let [selectedSubscription, setSelectedSubscription] = useState(false)

    let lang = cart ? cart.language : "en";
    let promo = cart ? cart.promo_code : "";
    
    useEffect(() => {
      const fetchData = async () => {
        let _product = await getProductBySlug(slug, "es", promo);

        if(Array.isArray(_product.data)){
          setProduct(_product.data[0])
        }else{
          setProduct(_product.data)
        }
      } 
      fetchData()
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        if(product){
            let packsIds = Object.keys(product.products_pack);
            let productIdsRelated = [product.id, ...packsIds];
            let _comment = await getProductComments(productIdsRelated, lang);
            let filteredComments = [];
            _comment[0].forEach((comment) => {
              if (
                comment.comment &&
                comment.rating > 4 &&
                filteredComments.length < 5
              ) {
                filteredComments.push(comment);
              }
            });
        
            setReviews(filteredComments);
        }
      }
      fetchData();
    }, [product, lang])

    function addProductToCart(){
      addProduct(product)
    }

    if(!product){
      return (
        <span></span>
      )
    }

    return (
        <>
          <Header />
          <div id="product-single">
            <section className="product-single">
            {product.media && product.media.length > 1 ?
              <Carousel element="carousel-class-to-inject" options={{startAt: 1, perView: 1, autoplay: 200000, gap: 20, type: "slider", peek: 50}}>
                {product.media.map((photo, i) => (
                  <img 
                    src={`https://resizing.cocunat.com/r/?quality=90&fit=cover&width=420&height=525&image=https://global.cocunat.com/photos/${photo.media}`}
                    key={'media-' + i} 
                    height="525" 
                    width="420"
                    className="custom-loader hero-loading-img"
                    alt="Hero" />
                ))}
                </Carousel>
            :
              <div style={{textAlign:"center", marginBottom:"20px"}}>
                  <img 
                  src={`https://resizing.cocunat.com/r/?quality=90&fit=cover&width=438&height=375&image=https://global.cocunat.com/photos/${product.image}`}
                  layout="responsive"
                  data-hero
                  className="custom-loader hero-loading-img"
                  alt="Hero"
                  style={{maxWidth:"100%"}} />
              </div>
            }
            
            <div className="container-small">
              <div id="title">
                <h1>{product.translation.name}</h1>
                <h2>{product.translation.tagline}</h2>
                {parseInt(product.attributes.metric_size) > 0 &&
                  <small>{parseInt(product.attributes.metric_size)}ML</small>
                }
                <h2>
                  <Price prices={product.prices}  />
                </h2>
              </div>
              <p id="rating" style={{textAlign: "center"}}>
                <RatingStars rating={product.translation.rating} />
                <span style={{marginLeft: 10}}>
                  {Math.round(parseFloat(product.translation.rating))}/5 
                  <a href="#comments" style={{marginLeft: 10}}>{t('comments', lang)}</a>
                </span>
              </p>
              
              {/*
              <RadioGroup
                options={[
                  {"value": false, "title": t('individual_purchase', lang)},
                  {"value": CONSTANTS.SUBSCRIPTION_TYPES.GOURMET, "title": t('purchase_with_gourmet',lang)}
                ]}
                defaultValue={false}
                onChange={(option) => setSelectedSubscription(option.value)}
              />
              <p>{JSON.stringify(selectedSubscription)}</p>
              */}

              <div id="summary">
                <div dangerouslySetInnerHTML={{ __html: product.translation.summary }} />
              </div>

              <Button 
                style={{margin:"30px auto"}} 
                size="big" 
                width="wide" 
                onClick={addProductToCart}
              >
                {t('add-to-cart', lang)}
              </Button>

              <Packaging />
            </div>
              <ProductVideo product={product} />


              <section id="description" className="container-small">
                <h3>{product.translation.catchy_title}</h3>
                <div dangerouslySetInnerHTML={{ __html: product.translation.description }} />
              </section>

              <section id="ingredients">
                <h4 className="heart-title">
                  {t('ingredients-title', lang)}
                </h4>
                {product.products_pack.length === 0 && product.translation.is_infographic &&
                  <>
                    {product.translation.ingredients.map((ingredient, i) => {
                      return (
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 incii-img" key={'ingredient-' + i}>  
                            <img 
                            src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/${ingredient.icon}`}
                            width="310" 
                            height="596" 
                            alt="Results"
                            sizes="(min-width: 650px) 100%, 100%" />
                            
                        </div>
                      )
                    })}
                  </>
                }
                <div className="container-small">
                  <small dangerouslySetInnerHTML={{ __html: product.attributes.ingredients }} />
                </div>
              </section>

              {product.translation.tutorial.length > 0 &&
                <section id="how-to-use">
                  <div className="container-small">
                      <h4 style={{color: "white"}}>{t('tutorial-title', lang)}</h4>
                      {product.translation.tutorial.map((step, i) => {
                          return(
                          <div className="step" key={'step-' + i}>
                              {step.icon &&
                                  <img 
                                  src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/${step.icon}&width=50&height=50`}
                                  alt={`Tutorial step ${i}`}
                                  height="50"
                                  width="50" />
                              }
                              <span style={{display:"block",margin:"10px 0px"}} dangerouslySetInnerHTML={{ __html: step.text }} />
                          </div>
                          )
                      })}
                  </div>
                </section>
              }

              {product.translation.ideal_for &&
                <section id="ideal-for">
                  <img
                    src={`https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/${product.translation.ideal_for_image}&width=450&height=700`}
                    height="560"
                    layout="responsive"
                    width="360"
                    alt="Ideal for"
                    style={{width:"100%", height:"auto"}} />
                  <div className="container-small">
                    <div className="ideal-for-text">
                      <div className="text-box">
                          <h4>{t('ideal-for-title', lang)}</h4>
                          <p
                            style={{textAlign:"center"}}
                            dangerouslySetInnerHTML={{ __html: product.translation.ideal_for }} 
                          ></p>
                      </div>
                    </div>
                  </div>
                </section>
              }

              <a name="comments"></a>

              <section id="reviews">
                <div className="comment-list" style={{padding: "0px 15px"}}>
                  {comments &&
                    comments.map((comment, i) => {
                      return (
                        <Comment 
                          title={comment.title} 
                          comment={comment.comment}
                          author={comment.user_name}
                          rating={comment.rating}
                          key={'comment-' + i}
                        />
                      )
                    })
                  }
                </div>
              </section>

            </section>
          </div>
      </>
    )
}

export default ProductSingle;