import Price from './Price'
import {buildPhotoUrl ,t} from '../helpers'
import { CartContext } from '../context/cart';
import React, {useContext} from 'react';

function CartLine(props){
    const cart = useContext(CartContext)

    let lang = cart ? cart.language : "es";

    return (
        <div className={`orderline-row ${props.isPresent ? "orderline-present" : "" }`}>
            <div className="cart-image inner-orderline-row">
                <img 
                    src={buildPhotoUrl("photos/" + props.orderline.image)}
                    alt={props.orderline.name}
                />
            </div>
            <div className="inner-orderline-row orderline-name">
                <p>{props.orderline.name}</p>
                <div className="controls">
                    {props.plusOneProduct && <button onClick={() => props.plusOneProduct(props.orderline.id)}>+</button>}
                    <span>{props.orderline.number}</span>
                    {props.minusOneProduct && <button onClick={() => props.minusOneProduct(props.orderline.id)}>-</button>}

                    {props.deleteProduct && <span className="delete-orderline" onClick={() => props.deleteProduct(props.orderline.id)}>{t('delete',lang).toUpperCase()}</span>}
                </div>
            </div>
            <div className="price inner-orderline-row">
                <p>
                <Price prices={props.orderline} className="product-loop" />
                </p>
            </div>
        </div>
    )
}

export default CartLine;