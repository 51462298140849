function Packaging(props){
    return (
        <div id="packaging" style={{margin:"10px 0px 20px 0px", flexGrow:"1"}}>
            <div className="row">
                <div className="packaging-item col-xs-6">
                    <img 
                    src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=36&height=36&image=https://global.cocunat.com/icons/9.png" 
                    width="36" 
                    height="36" 
                    alt="Cruelty Free" />
                    <span>Cruelty Free</span>
                </div>
                <div className="packaging-item col-xs-6">
                    <img 
                    src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=36&height=17&image=https://global.cocunat.com/icons/10.png" 
                    width="36" 
                    height="17" 
                    alt="Approved by Peta" />
                    <span>Approved by Peta</span>
                </div>
                <div className="packaging-item col-xs-6">
                    <img 
                    src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=36&height=36&image=https://global.cocunat.com/icons/11.png" 
                    width="36" 
                    height="36" 
                    alt="Vegan" />
                    <span>Vegan</span>
                </div>
                <div className="packaging-item col-xs-6">
                    <img 
                    src="https://resizing.cocunat.com/r/?quality=90&format=webp&fit=cover&width=36&height=36&image=https://global.cocunat.com/icons/12.png" 
                    width="36" 
                    height="36" 
                    alt="No nasties" />
                    <span>No nasties</span>
                </div>
            </div>
        </div>
    )
}

export default Packaging;