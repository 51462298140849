import {createContext, useEffect, useState} from "react"
import constants from "../constants";
import {getCartTotals, getNumberOfProductsFromOrderlines, getPromo} from "../logic/cart";
import {v4 as uuidv4} from 'uuid';
import {getProductsPrices} from "../logic/products";

const CartContext = createContext(constants.DEFAULT_CART)


function CartContextProvider({children}) {
    const [cart, setCart] = useState();
    const [paidCart, setPaidCart] = useState();
    const [totals, setTotals] = useState(null)
    const [promo, setPromo] = useState(null)

    function _handleCartRewards(totalsResponse) {
        let responseRewards = totalsResponse.data.rewards;
        let newRewardProducts = [];
        if (responseRewards && responseRewards.products) {
            newRewardProducts = responseRewards.products;
        }
        let cartCopy = {...cart};
        if (cartCopy.reward_code !== responseRewards.code) {
            cartCopy.reward_code = responseRewards.code;
            cartCopy.presents = cartCopy.presents.filter((product) => !product.hasOwnProperty("is_reward") || !product.is_reward);
            cartCopy.presents = cartCopy.presents.concat(newRewardProducts);
            setCart(cartCopy);
        }
    }

    async function _getCartTotals() {
        let totalsResponse = await getCartTotals(cart);
        if (totalsResponse.status === 200) {
            setTotals(totalsResponse.data)
            _handleCartRewards(totalsResponse);
        }
    }

    async function _updatePromo() {
        let promoCode = cart.promo_code
        if (promoCode && (promo == null || promo.code !== promoCode)) {
            let promoResponse = await getPromo(promoCode)
            if (promoResponse.status === 200) {
                setPromo(promoResponse.data)
            }
        }
    }


    async function _getUpdatedCartOrderlines(promoCode) {
        let updatedCartOrderlines = []
        let products = await getProductsPrices(promoCode);
        for (let orderline of cart.orderlines) {
            let productPrice = products.data.find(product => parseInt(product.id) === orderline.id).prices;
            orderline.price = parseFloat(productPrice.price);
            orderline.discounted = parseFloat(productPrice.discounted);
            updatedCartOrderlines.push(orderline)
        }
        return updatedCartOrderlines;
    }


    function updateCartAttributes(attributes) {
        let cartCopy = {...cart};
        for (let attributeKey in attributes) {
            cartCopy[attributeKey] = attributes[attributeKey];
        }
        cartCopy.modified_date = new Date().toISOString();
        setCart(cartCopy);
    }

    async function applyCartPromo(promoCode) {
        let cartCopy = {...cart};
        cartCopy.promo_code = promoCode;
        cartCopy.modified_date = new Date().toISOString();
        cartCopy.promo_code_modified = new Date().toISOString();
        cartCopy.orderlines = await _getUpdatedCartOrderlines(promoCode);
        setCart(cartCopy);
    }

    function plusOneProduct(productId) {
        let cartCopy = {...cart}
        for (let i = 0; i < cartCopy.orderlines.length; i++) {
            if (cartCopy.orderlines[i].id === productId) {
                cartCopy.orderlines[i].number += 1
            }
        }

        cartCopy.modified_date = new Date().toISOString();
        cartCopy.number_products = getNumberOfProductsFromOrderlines(cartCopy)
        setCart(cartCopy)
    }

    function minusOneProduct(productId) {
        let cartCopy = {...cart}
        for (let i = 0; i < cartCopy.orderlines.length; i++) {
            if (cartCopy.orderlines[i].id === productId) {
                cartCopy.orderlines[i].number -= 1;
                if (cartCopy.orderlines[i].number <= 0) {
                    cartCopy.orderlines.splice(i, 1);
                }
            }
        }

        cartCopy.modified_date = new Date().toISOString();
        cartCopy.number_products = getNumberOfProductsFromOrderlines(cartCopy)
        setCart(cartCopy)
    }


    function deleteProduct(productId) {
        let cartCopy = {...cart}
        for (let i = 0; i < cartCopy.orderlines.length; i++) {
            if (cartCopy.orderlines[i].id === productId) {
                cartCopy.orderlines.splice(i, 1);
            }
        }

        cartCopy.modified_date = new Date().toISOString();
        cartCopy.number_products = getNumberOfProductsFromOrderlines(cartCopy)
        setCart(cartCopy)
    }

    function addProduct(productData) {
        let cartCopy = {...cart}
        let productExists = false;
        for (let i = 0; i < cartCopy.orderlines.length; i++) {
            if (parseInt(cartCopy.orderlines[i].id) === parseInt(productData.id)) {
                cartCopy.orderlines[i].number += 1;
                productExists = true;
            }
        }
        if (!productExists) {
            cartCopy.orderlines.push({
                "id": parseInt(productData.id),
                "name": productData.translation.name,
                "image": productData.image,
                "price": parseFloat(productData.prices.price),
                "number": 1,
                "presale": false,
                "region": "SPAIN",
                "base_discount": parseFloat(productData.prices.base_discount),
                "discounted": parseFloat(productData.prices.discounted)
            });
        }

        cartCopy.modified_date = new Date().toISOString();
        cartCopy.number_products = getNumberOfProductsFromOrderlines(cartCopy)
        setCart(cartCopy)
    }

    function markCartAsPaid() {
        if (cart) {
            if (paidCart) {
                sessionStorage.removeItem('paid_cart')
            }
            setPaidCart(cart)
            sessionStorage.setItem('paid_cart', JSON.stringify(cart))
            sessionStorage.removeItem('cart')
        }
    }

    function emptyCart() {
        let copyCart = {...constants.DEFAULT_CART};
        copyCart.cart_id = uuidv4();
        copyCart.country = "ES";
        copyCart.region = "SPAIN";
        copyCart.start_date = new Date().toISOString();
        copyCart.modified_date = new Date().toISOString();
        setCart(copyCart)
    }

    useEffect(() => {
        const cartData = JSON.parse(sessionStorage.getItem('cart'))
        if (cartData) {
            setCart(cartData)
        } else {
            emptyCart()
        }
        const paidCartData = JSON.parse(sessionStorage.getItem('paid_cart'))
        if (paidCartData) {
            setPaidCart(paidCartData)
        }

        const promoData = JSON.parse(sessionStorage.getItem('promo'))
        if (promoData) {
            setPromo(promoData)
        }
    }, [])

    useEffect(() => {
        if (cart) {
            sessionStorage.setItem('cart', JSON.stringify(cart))
            _getCartTotals()
            _updatePromo()
        }
    }, [cart])


    useEffect(() => {
        if (promo) {
            sessionStorage.setItem('promo', JSON.stringify(promo))
        }
    }, [promo])

    return (
        <CartContext.Provider value={{
            cart,
            totals,
            setCart,
            plusOneProduct,
            minusOneProduct,
            deleteProduct,
            addProduct,
            updateCartAttributes,
            markCartAsPaid,
            paidCart,
            applyCartPromo,
            promo
        }}>
            {children}
        </CartContext.Provider>
    )
}

export {CartContextProvider, CartContext}