import React, {useContext, useState, useEffect} from 'react';
import { Redirect } from "react-router-dom";
import { CartContext } from '../context/cart';
import Header from '../components/Header';
import Button from '../components/Button';
import RadioGroup from '../components/RadioGroup';
import CartTotals from '../components/CartTotals';
import InputGroup from '../components/InputGroup';
import GoBackLink from '../components/GoBackLink';
import constants from '../constants';
import { inputValidationError } from '../logic/helpers'
import {t} from '../helpers'


function CheckoutAddress() {
    const {cart, updateCartAttributes} = useContext(CartContext);

    const [redirectTo, setRedirectTo] = useState(false);
    const [address, setAddress] = useState(constants.BLANK_ADDRESS)
    const [shippingChoice, setShippingChoice] = useState({"value": 1});
    const [error, setError] = useState(false)
    let lang = cart ? cart.language : "en";

    function onChangeShipping(option){
        let blankAddressWithNames = constants.BLANK_ADDRESS
        blankAddressWithNames.first_name = address.first_name;
        blankAddressWithNames.last_name = address.last_name;
        blankAddressWithNames.email = address.email;

        setAddress(blankAddressWithNames);
        setShippingChoice(option);
    }

    function changeAddress(e){
        let addressCopy = {...address};
        addressCopy[e.target.name] = e.target.value;
        setAddress(addressCopy);
    }

    
    function saveAddress(){
        if(address.email && address.email !== '' && shippingChoice){
            let carrier = shippingChoice.value === 1 ? constants.CARRIER_ID_PICKUP_STORE : null
            let selectedAddress = address
            let allowCheckout = true
            if(shippingChoice.value === 1){
                let storeAddress = constants.STORE_ADDRESS;
                storeAddress.first_name = address.first_name;
                storeAddress.last_name = address.last_name;
                storeAddress.email = address.email;
                selectedAddress = storeAddress;; 
            }

            Object.keys(selectedAddress).forEach(function(key) {
                let error = inputValidationError(key, selectedAddress[key]);
                if (error){
                    setError(error)
                    allowCheckout = false
                }
            });
            if (allowCheckout){
                updateCartAttributes({"carrier": carrier, "address": selectedAddress});
                setRedirectTo(`/${lang}/checkout/`)
            }
            
        }else{
            setError("Todos los campos son obligatorios")
        }
    }

    useEffect(() => {
        if(cart && cart.address){
            if(cart.carrier === constants.CARRIER_ID_PICKUP_STORE){
                setShippingChoice({"value": 1})
            }else{
                setShippingChoice({"value": 2})
            }
            setAddress(cart.address)
        }
    }, [cart]);

    if(redirectTo){
        return <Redirect to={redirectTo} />
    }

    return (
        <>
            <Header />

            <div className="container-small"> 
                <GoBackLink to={`/${lang}/cart/`} title={t('go_to_cart',lang)} />
            </div>

            {cart &&
                <>
                    <div className="cart-title-header">
                        <p>CHECKOUT</p>
                    </div>
                    <div className="container-small">
                        {error && <div className="alert alert-danger">⚠ {error}</div>}

                        <div className="row" style={{marginTop: 50}}>
                            <div className="col-xs-6">
                                <InputGroup
                                    id="name"
                                    name="first_name"
                                    title={t('name',lang)}
                                    value={address.first_name}
                                    onChange={changeAddress}
                                />
                            </div>
                            <div className="col-xs-6">
                                <InputGroup 
                                    id="last_name"
                                    name="last_name"
                                    title={t('last_name',lang)}
                                    value={address.last_name}
                                    onChange={changeAddress}
                                />
                            </div>
                        </div>

                        <InputGroup 
                            id="email"
                            name="email"
                            title={t('your_email',lang)}
                            value={address.email}
                            onChange={changeAddress}
                            style={{marginBottom: 50}}
                        />

                        <RadioGroup
                            options={[{"value": 1, "title": t('delivery_shop',lang)},{"value": 2, "title": t('delivery_home',lang)}]}
                            defaultValue={shippingChoice}
                            onChange={onChangeShipping}
                        />

                        {shippingChoice.value === 2 &&
                            <>
                                <InputGroup 
                                    id="address"
                                    name="address"
                                    title={t('address',lang)}
                                    value={address.address}
                                    onChange={changeAddress}
                                    style={{marginTop: 20}}
                                />
                                <div className="row">
                                    <div className="col-xs-6">
                                        <InputGroup 
                                            id="zip_code"
                                            name="zip_code"
                                            title={t('zip_code',lang)}
                                            value={address.zip_code}
                                            onChange={changeAddress}
                                        />
                                    </div>
                                    <div className="col-xs-6">
                                        <InputGroup 
                                            id="city"
                                            name="city"
                                            title={t('city',lang)}
                                            value={address.city}
                                            onChange={changeAddress}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-4" style={{paddingRight: 0}}>
                                        <div className="input-group">    
                                            <select 
                                                id="prefix_phone"
                                                name="prefix_phone"
                                                onChange={changeAddress}
                                                style={{borderRadius: "4px 0px 0px 4px", borderRightWidth: 0}}
                                            >
                                                {constants.COUNTRIES.map((country, i) => {
                                                    return (
                                                        <option key={i} value={country.prefix}>{country.prefix} ({country.name})</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xs-8" style={{paddingLeft: 0}}>
                                        <InputGroup 
                                            id="phone"
                                            name="phone"
                                            title={t('phone',lang)}
                                            value={address.phone}
                                            onChange={changeAddress}
                                            style={{borderRadius: "0px 4px 4px 0px"}}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>


                    <div className="container-small" >
                        <div style={{padding: "20px 0px"}}>
                        <CartTotals />
                        </div>
                        <Button size="big" width="wide" onClick={saveAddress}>
                            CHECKOUT
                        </Button>
                    </div>
                </>
            }
        </>
    )
}

export default CheckoutAddress;