import React, {useContext, useEffect} from 'react';
import {CartContext} from "../context/cart";
import {overrideBodyClass, buildPhotoUrl,t} from '../helpers'

function Thanks() {
    const {
        paidCart
    } = useContext(CartContext)
    useEffect(() => {
        document.body.classList.add("Hello")
        overrideBodyClass(document.body.classList, "intro");
    }, []);
    
    useEffect(() => {
        const timer = setTimeout(() => {
            sessionStorage.clear()
            window.location.replace("https://store.cocunat.com/")
        }, 10000);
        return () => clearTimeout(timer);
    }, []);

    let lang = paidCart ? paidCart.language : "en";


    return (
        <>
            {paidCart &&
            <div className="container thanks">
                <img 
                    src={buildPhotoUrl("photos/store-checkout-ok.png")}
                    alt=""
                />
                <p style={{fontSize:"30px", fontFamily:"Brown-Bold", color:"white"}}>{t('thanks', lang, {name:paidCart.address.first_name})}</p>
                <p style={{fontSize:"25px", fontFamily:"Brown-Regular", color:"white"}}>{t('go_caja',lang)}</p>
                <div style={{textAlign:"center", marginTop:"150px"}}>
                    <p style={{color:"black"}}>© 2020 Cocunat. All Rights Reserved</p>
                </div>            
            </div>}
        </>
    )
}

export default Thanks;