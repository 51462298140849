import translations from './locale'

export function overrideBodyClass(classList, newClass){
    document.body.classList.remove(...classList);
    document.body.classList.add(newClass)
}

export function buildPhotoUrl(image, height=150, width=150, fit="contain", quality=90){
    let domainS3 = "https://global.cocunat.com";
    let resizeDomain = "https://resizing.cocunat.com/r/"
    let imageUrl = `${domainS3}/${image}`
    return `${resizeDomain}?quality=${quality}&fit=${fit}&width=${width}&height=${height}&image=${encodeURIComponent(imageUrl)}`;
}

export function t(key, language, params = {}){
    let trans = "";
    try{
        trans = translations[language][key]
    }catch(err){
      trans = key;
    }
  
      for (key in params){
          trans = trans.replace("{{" + key + "}}", params[key])
      }
      return trans
  }