import { useContext } from "react";
import {Link} from "react-router-dom";
import { CartContext } from "../context/cart";
import { buildPhotoUrl } from '../helpers';
import {t} from '../helpers'


function CategoryBar({activeCategory}){
    const {cart} = useContext(CartContext)
    let lang = cart ? cart.language : "es";

    return (
        <section id="category-bar">
            <div className="container">
                <Link to={`/${lang}/search/`} className={`col-md-2 col-sm-2 col-xs-4 ${!activeCategory ? "active" : "inactive"}`}>
                    <img 
                        src={buildPhotoUrl("sello.png", 75, 75)}
                        alt="Cocunat"
                        height="75"
                        width="75"
                        loading="lazy"
                    />
                    <span>{t('all-products', lang).toUpperCase()}</span>
                </Link>
                <Link to={`/${lang}/search/65/`} className={`col-md-2 col-sm-2 col-xs-4 ${activeCategory === "65" ? "active" : "inactive"}`}>
                    <img 
                        src={buildPhotoUrl("store-facial.png", 75, 75)}
                        alt="Face"
                        height="75"
                        width="75"
                        loading="lazy"
                    />
                    <span>{t('face', lang).toUpperCase()}</span>
                </Link>
                <Link to={`/${lang}/search/66/`} className={`col-md-2 col-sm-2 col-xs-4 ${activeCategory === "66" ? "active" : "inactive"}`}>
                    <img 
                        src={buildPhotoUrl("store-body.png", 75, 75)}
                        alt="Body"
                        height="75"
                        width="75"
                        loading="lazy"
                    />
                    <span>{t('body', lang).toUpperCase()}</span>
                </Link>
                <Link to={`/${lang}/search/67/`} className={`col-md-2 col-sm-2 col-xs-4 ${activeCategory === '67' ? "active" : "inactive"}`}>
                    <img 
                        src={buildPhotoUrl("store-hair.png", 75, 75)}
                        alt="Hair"
                        height="75"
                        width="75"
                        loading="lazy"
                    />
                    <span>{t('hair', lang).toUpperCase()}</span>
                </Link>
                <Link to={`/${lang}/search/90/`} className={`col-md-2 col-sm-2 col-xs-4 ${activeCategory === '90' ? "active" : "inactive"}`}>
                    <img 
                        src={buildPhotoUrl("store-packs.png", 75, 75)}
                        alt="Cocunat"
                        height="75"
                        width="75"
                        loading="lazy"
                    />
                    <span>{t('packs', lang).toUpperCase()}</span>
                </Link>
                <Link to={`/${lang}/search/80/`} className={`col-md-2 col-sm-2 col-xs-4 ${activeCategory === '80' ? "active" : "inactive"}`}>
                    <img 
                        src={buildPhotoUrl("store-cnmu.png", 75, 75)}
                        alt="Cocunat"
                        height="75"
                        width="75"
                        loading="lazy"
                    />
                    <span>C.NOMAKEUP</span>
                </Link>
            </div>
        </section>
    )
}


export default CategoryBar;