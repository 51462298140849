function ratingStars(rating){
    let stars = "";
    Array.from({ 
        length: Math.round(rating) 
    }, (_, i) => {
        stars += "★"
    })

    Array.from({ 
        length: 5 - Math.round(rating)
    }, (_, i) => {
        stars += "☆"
    })

    return stars;
}

function RatingStars({rating}){
    rating = parseFloat(rating)
    return (
        <span style={{color: "#ffc107"}}>
            {ratingStars(rating)}
        </span>
    )
}

export default RatingStars;