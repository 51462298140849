import axios from 'axios';
import constants from '../constants';


function getUrlPromo(promo){
    return `&promo=${promo}`
}
export async function getProducts(lang,promo=""){
    let promoUrl = ""
    if (promo !== ''){
        promoUrl = getUrlPromo(promo)
    }
    return await axios.get(`https://proxy.cocunat.com/products/?lang=${lang}&region=SPAIN${promoUrl}`)
}

export async function getProductComments(productIdsList, lang){
    let urls = [];
    for(let i = 0; i < productIdsList.length; i++){
        urls.push(`https://reviews-production.cocunat.workers.dev/comments/?language=${lang}&product_id=${productIdsList[i]}`)
    }

    function fetchData(URL) {
        return axios
          .get(URL)
          .then(function(response) {
            return response.data
          })
      }

    const promiseArray = [urls].map(fetchData);

    return Promise.all(promiseArray).then(response => {
        return response;
    })
}

// return await axios.get(`https://reviews-production.cocunat.workers.dev/comments/?language=${lang}&product_id=${productId}`)

export async function getProductsPrices(promo=""){
    let promoUrl = ""
    if (promo !== ''){
        promoUrl = getUrlPromo(promo)
    }
    return await axios.get(`https://proxy.cocunat.com/products/?region=SPAIN${promoUrl}`)
}


export async function getProductById(id, lang,promo=""){
    let promoUrl = ""
    if (promo !== ''){
        promoUrl = getUrlPromo(promo)
    }
    return await axios.get(`https://proxy.cocunat.com/products/${id}?lang=${lang}&region=SPAIN${promoUrl}`)
}

export async function getProductsByCategory(categoryId, lang,promo=""){
    let promoUrl = ""
    if (promo !== ''){
        promoUrl = getUrlPromo(promo)
    }
    return await axios.get(`https://proxy.cocunat.com/products/category/${categoryId}?lang=${lang}&region=SPAIN${promoUrl}`)
}

export async function getProductsByCategories(categoryId, lang,promo=""){
    let promoUrl = ""
    if (promo !== ''){
        promoUrl = getUrlPromo(promo)
    }
    return await axios.get(`https://proxy.cocunat.com/products/categories/${categoryId}?lang=${lang}&region=SPAIN${promoUrl}`)
}

export async function searchProducts(query, lang,promo=""){
    let promoUrl = ""
    if (promo !== ''){
        promoUrl = getUrlPromo(promo)
    }
    return await axios.get(`https://proxy.cocunat.com/products/search?q=${query}&lang=${lang}&region=SPAIN${promoUrl}`)
}

export async function getProductBySlug(slug, lang,promo=""){
    let promoUrl = ""
    if (promo !== ''){
        promoUrl = getUrlPromo(promo)
    }
    return await axios.get(`https://proxy.cocunat.com/products/slug/${slug}/?lang=${lang}&region=SPAIN${promoUrl}`)
}

export function getProductSkeletons(number){
    let skeletons = [];
    for(let i = 0; i < number; i++){
        skeletons.push(constants.SKELETON_PRODUCT)
    }
    return skeletons;
}