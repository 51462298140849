const SKELETON_PRODUCT = {
    id: "skeleton",
    image: null,
    translation: {
        name: null,
        slug: null
    }
}

const CARRIER_ID_PICKUP_STORE = 6;
const DEFAULT_LANG = "es";
const DEFAULT_COUNTRY = "ES";
const DEFAULT_REGION = "SPAIN";

const GATEWAYS = {
    Stripe: 1,
    Paypal: 2,
    Braintree: 3,
    AmazonCheckout: 4,
    Klarna: 5,
    Popup: 6
};

const DEFAULT_CART = {
    cart_id: null,
    start_date: null,
    carrier: CARRIER_ID_PICKUP_STORE,
    modified_date: null,
    promo_code: "",
    reward_code: null,
    number_products: 0,
    promo_code_modified: null,
    country: DEFAULT_COUNTRY,
    language: DEFAULT_LANG,
    region: DEFAULT_REGION,
    currency: "EUR",
    totals: {},
    orderlines: [],
    presents: [],
    postalCode: null,
    totalsIsLoading: false,
    meta: {},
    address: {
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        zip_code: "",
        city: "",
        prefix: "",
        phone: "",
        country: "",
    },
    origin: "store.cocunat.com",
    gateway: GATEWAYS.Popup
}



const MOCK_CART = {
    "cart_id": "8d681b32-aa56-4243-992a-21ab739674aa",
    "start_date": "2020-10-23T08:11:54.780Z",
    "modified_date": "2021-10-08T10:49:10.771Z",
    "promo_code": "",
    "promo_code_modified": null,
    "country": "es",
    "language": "es",
    "region": "SPAIN",
    "totals": {
        "subtotal": 279.35,
        "discount": 0,
        "balance": 5.16,
        "promotions": {
            "quantity": 0,
            "present": {},
            "shipping": true
        },
        "subtotal_with_discounts": 274.19,
        "shipping": 0,
        "suplements": 0,
        "total": 274.19
    },
    "orderlines": [{
        "id": 53214,
        "name": "Natural Protector SPF 30",
        "image": "1615553430601_solar2.png",
        "price": "23.95",
        "number": 3,
        "presale": false,
        "region": "SPAIN",
        "base_discount": "0.00"
    }, {
        "id": 53206,
        "name": "Body Mousse Exotic Orange",
        "image": "1611237710487_exoticorange.png",
        "price": "21.95",
        "number": 4,
        "presale": false,
        "region": "SPAIN",
        "base_discount": "0.00"
    }, {
        "id": 53009,
        "name": "Booster de Rizos",
        "image": "1615543343282_booster.png",
        "price": "19.95",
        "number": 6,
        "presale": false,
        "region": "SPAIN",
        "base_discount": "0.00"
    }],
    "presents": [],
    "postalCode": "08032",
    "totalsIsLoading": false,
    "meta": {
        "utms": "{\"utm_campaign\":\"FB\"}"
    },
    "address": {
        "first_name": "Marcos",
        "last_name": "Aguayo",
        "email": "marcos@aguayo.es",
        "address": "Doctor Pi i Molist 72, 3r 2a",
        "zip_code": "08032",
        "city": "Barcelona",
        "prefix": "",
        "phone": "630245253",
        "country": "es",
        "id": 520,
        "created": "2020-03-12T21:59:44.087866Z",
        "modified": "2020-03-12T21:59:44.087895Z",
        "rest_address": "",
        "observations": "",
        "deleted": false,
        "anonymous": false,
        "document_id": "",
        "user": 1
    },
    "user_id": 1,
    "subscription_id": 0,
    "is_refill": false,
    "subscription_refill_modified": null
}

const COUNTRIES = [
    {
        code: "AL",
        name: "Albania",
        prefix: "+355",
    },
    {
        code: "AM",
        name: "Armenia",
        prefix: "+374",
    },
    {
        code: "AT",
        name: "Austria",
        prefix: "+43",
    },
    {
        code: "AU",
        name: "Australia",
        prefix: "+61",
    },
    {
        code: "BA",
        name: "Bosnia and Herzegovina",
        prefix: "+387",
    },
    {
        code: "BE",
        name: "Belgium",
        prefix: "+32",
    },
    {
        code: "BG",
        name: "Bulgaria",
        prefix: "+359",
    },
    {
        code: "CA",
        name: "Canada",
        prefix: "+1",
    },
    {
        code: "CO",
        name: "Colombia",
        prefix: "+57",
    },
    {
        code: "CH",
        name: "Switzerland",
        prefix: "+41",
    },
    {
        code: "CY",
        name: "Cyprus",
        prefix: "+357",
    },
    {
        code: "CZ",
        name: "Czech Republic",
        prefix: "+420",
    },
    {
        code: "DE",
        name: "Germany",
        prefix: "+49",
    },
    {
        code: "DK",
        name: "Denmark",
        prefix: "+45",
    },
    {
        code: "EE",
        name: "Estonia",
        prefix: "+372",
    },
    {
        code: "ES",
        name: "Spain (España)",
        prefix: "+34",
    },
    {
        code: "FI",
        name: "Finland",
        prefix: "+358",
    },
    {
        code: "FO",
        name: "Faroe Islands",
        prefix: "+298",
    },
    {
        code: "FR",
        name: "France",
        prefix: "+33",
    },
    {
        code: "GB",
        name: "United Kingdom",
        prefix: "+44",
    },
    {
        code: "GE",
        name: "Georgia",
        prefix: "+995",
    },
    {
        code: "GL",
        name: "Greenland",
        prefix: "+299",
    },
    {
        code: "GR",
        name: "Greece",
        prefix: "+30",
    },
    {
        code: "HR",
        name: "Croatia",
        prefix: "+385",
    },
    {
        code: "HU",
        name: "Hungary",
        prefix: "+36",
    },
    {
        code: "IE",
        name: "Ireland",
        prefix: "+353",
    },
    {
        code: "IM",
        name: "Isle of Man",
        prefix: "+44",
    },
    {
        code: "IS",
        name: "Iceland",
        prefix: "+354",
    },
    {
        code: "IT",
        name: "Italy",
        prefix: "+39",
    },
    {
        code: "LI",
        name: "Liechtenstein",
        prefix: "+423",
    },
    {
        code: "LT",
        name: "Lithuania",
        prefix: "+370",
    },
    {
        code: "LU",
        name: "Luxembourg",
        prefix: "+352",
    },
    {
        code: "LV",
        name: "Latvia",
        prefix: "+371",
    },
    {
        code: "MC",
        name: "Monaco",
        prefix: "+377",
    },
    {
        code: "MD",
        name: "Moldova",
        prefix: "+373",
    },
    {
        code: "ME",
        name: "Montenegro",
        prefix: "+382",
    },
    {
        code: "MK",
        name: "Macedonia",
        prefix: "+389",
    },
    {
        code: "MT",
        name: "Malta",
        prefix: "+356",
    },
    {
        code: "NL",
        name: "Netherlands",
        prefix: "+31",
    },
    {
        code: "NO",
        name: "Norway",
        prefix: "+47",
    },
    {
        code: "NZ",
        name: "New Zealand",
        prefix: "+64",
    },
    {
        code: "PL",
        name: "Poland",
        prefix: "+48",
    },
    {
        code: "PT",
        name: "Portugal",
        prefix: "+351",
    },
    {
        code: "RO",
        name: "Romania",
        prefix: "+40",
    },
    {
        code: "RS",
        name: "Serbia",
        prefix: "+381",
    },
    {
        code: "SE",
        name: "Sweden",
        prefix: "+46",
    },
    {
        code: "SI",
        name: "Slovenia",
        prefix: "+386",
    },
    {
        code: "SJ",
        name: "Svalbard and Jan Mayen",
        prefix: "+47",
    },
    {
        code: "SK",
        name: "Slovakia",
        prefix: "+421",
    },
    {
        code: "SM",
        name: "San Marino",
        prefix: "+378",
    },
    {
        code: "UA",
        name: "Ukraine",
        prefix: "+380",
    },
    {
        code: "US",
        name: "United States",
        prefix: "+1",
    },
    {
        code: "XK",
        name: "Kosovo",
        prefix: "+383",
    },
    {
        code: "PE",
        name: "Peru",
        prefix: "+51",
    },
    {
        code: "PH",
        name: "Phillipines",
        prefix: "+63",
    },
    {
        code: "IL",
        name: "Israel",
        prefix: "+972",
    },
    {
        code: "SA",
        name: "Arabia Saudi",
        prefix: "+966",
    },
    {
        code: "PR",
        name: "Puerto Rico",
        prefix: "+1",
    },
];

const BLANK_ADDRESS = {
    "first_name": "",
    "last_name": "",
    "email": "",
    "address": "",
    "zip_code": "",
    "city": "",
    "prefix_phone": "",
    "phone": "",
    "country": "",
}

const WORKER_CHECKOUTS = "https://checkouts.cocunat.workers.dev/";
const AWS_CART_CHECKOUTS = "https://cocunatapi.net/checkout/carts/";

const CONSTANTS = {
    DEFAULT_CART: DEFAULT_CART,
    MOCK_CART: MOCK_CART,
    SKELETON_PRODUCT: SKELETON_PRODUCT,
    DEFAULT_LANG: DEFAULT_LANG,
    CARRIER_ID_PICKUP_STORE: CARRIER_ID_PICKUP_STORE,
    COUNTRIES: COUNTRIES,
    DEFAULT_COUNTRY: DEFAULT_COUNTRY,
    BLANK_ADDRESS: BLANK_ADDRESS,
    SUBSCRIPTION_TYPES: {"GOURMET": "GOURMET", "SIMPLE": "SIMPLE"},
    STORE_ADDRESS: {
        "address": "Rambla Catalunya 89 local 1 (Mallorca 247)",
        "city": "Barcelona",
        "zip_code": "08008",
        "country": "ES",
        "prefix_phone": "+34",
        "phone": "911980581",
    },
    GATEWAYS: GATEWAYS,
    WORKER_CHECKOUTS: WORKER_CHECKOUTS,
    AWS_CART_CHECKOUTS: AWS_CART_CHECKOUTS
}

export default CONSTANTS;