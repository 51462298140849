function Button(props){
    let type = props.type || "primary";
    let size = props.size || "regular";
    let width = props.width || "width-regular";

    return (
        <>
            <button
                onClick={props.onClick}
                className={`${type} ${size} ${width}`}
                style={props.style}
            >
                {props.children}
            </button>
        </>
    )
}

export default Button;