import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './index.css';
import Home from './screens/Home';
import Cart from './screens/Cart';
import CheckoutShippingChoice from './screens/CheckoutAddress';
import Search from './screens/Search';
//import reportWebVitals from './reportWebVitals';
import {CartContextProvider} from './context/cart';
import ProductSingle from './screens/ProductSingle';
import CheckoutAddress from './screens/CheckoutAddress';
import ScanProduct from './screens/ScanProduct'

import Thanks from "./screens/Thanks";
import CheckoutError from "./screens/CheckoutError";



Sentry.init({
  dsn: "https://96dfa8beb09646d7a08eea99644914ab@o127200.ingest.sentry.io/5995212",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});

const Checkout = React.lazy(() => import('./screens/Checkout'));

function AppRouter(){
  return (
    <CartContextProvider>
      <React.Suspense fallback={<span>Loading...</span>}>
        <Router>
          <Switch>
            <Route path="/:lang([a-z]{2})/search/:categoryId">
              <Search />
            </Route>
            <Route path="/:lang([a-z]{2})/search">
              <Search />
            </Route>
            <Route path="/:lang([a-z]{2})/scan">
              <ScanProduct />
            </Route>
            <Route path="/:lang([a-z]{2})/cart">
              <Cart />
            </Route>
            <Route path="/:lang([a-z]{2})/checkout/address">
              <CheckoutAddress />
            </Route>
            <Route path="/:lang([a-z]{2})/checkout/thanks">
            <Thanks />
            </Route>
            <Route path="/:lang([a-z]{2})/checkout/error">
            <CheckoutError />
            </Route>
            <Route path="/:lang([a-z]{2})/checkout">
              <Checkout />
            </Route>
            <Route path="/:lang([a-z]{2})/products/:slug">
              <ProductSingle />
            </Route>
            <Route path="/:lang([a-z]{2})/">
              <Home />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </React.Suspense>
    </CartContextProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root')
);
