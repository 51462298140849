import {useContext} from 'react';
import {CartContext} from '../context/cart';
import {Link} from 'react-router-dom';
import Button from './Button';
import {t} from '../helpers'

function Header({goBack, payLink}){
    const {cart} = useContext(CartContext)
    let lang = cart ? cart.language : "es";

    if(!goBack){
        goBack = `/${lang}/search/`
    }

    if(!payLink){
        payLink = `/${lang}/cart/`
    }

    return (
        <header>
            <div className="container">
                <div style={{height: 30, width: 60}}>&nbsp;</div>
                {cart && cart.number_products > 0 ?
                    <>
                        <Link to={goBack} style={{paddingRight: 160}}>
                            <img 
                                decoding="async" 
                                alt="Cocunat"
                                height="22"
                                width="143"
                                src="https://resizing.cocunat.com/r/?quality=1&amp;fit=cover&amp;image=https://global.cocunat.com/photos/logo.png&amp;width=143&amp;height=22"
                            ></img>
                        </Link>
                        <Link to={`/${lang}/cart`} style={{position: "absolute", top: 6, right: 130}}>
                            <img
                                height="35"
                                width="27"
                                decoding="async"
                                alt="Cart"
                                src="https://resizing.cocunat.com/r/?quality=90&fit=contain&height=35&width=27&image=https%3A%2F%2Fglobal.cocunat.com%2Fcart-basket-black.png"
                            />
                            <span id="cart-header-number">
                                {cart.number_products}
                            </span>
                        </Link>
                        <Link to={payLink} style={{position: "absolute", top: 6, right: 5}}>
                            <Button size="regular">{t('pay',lang)}</Button>
                        </Link>
                    </>
                :
                    <>
                        <Link to={goBack}>
                            <img 
                                decoding="async" 
                                alt="Cocunat"
                                height="22"
                                width="143"
                                src="https://resizing.cocunat.com/r/?quality=1&amp;fit=cover&amp;image=https://global.cocunat.com/photos/logo.png&amp;width=143&amp;height=22"
                            ></img>
                        </Link>
                        <Link to={`/${lang}/cart`} style={{position: "absolute", top: 6, right: 10}}>
                            <img
                                height="35"
                                width="27"
                                decoding="async"
                                alt="Cart"
                                src="https://resizing.cocunat.com/r/?quality=90&fit=contain&height=35&width=27&image=https%3A%2F%2Fglobal.cocunat.com%2Fcart-basket-black.png"
                            />
                            <span id="cart-header-number">
                                0
                            </span>
                        </Link>
                    </>
                }
            </div>
        </header>
    )
}

export default Header;